<template>
  <div class='opt-partner'>
    <div class="container">
      <h2 class="title">{{$t('optPartner')}}</h2>
      <div class="opt-partner__list">

        <div class="opt-partner__items">
          <div class="opt-partner__item" v-for="(item,index) in $t('optPartners')" :key="index">
            <span>{{index + 1}}.</span>
            <p>{{item.text}}</p>
          </div>
        </div>

        <div class="opt-partner__items">
          <div class="opt-partner__item" v-for="(item,index) in $t('optPartnersRight')" :key="index">
            <span>{{index+4}}</span>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'OptPartner'
  }
</script>